import { Button } from "@/components/Elements";
import { ConfirmationDialog } from "@/components/Elements/ConfirmationDialog";
import { getSubscription } from "@/features/auth/api/getSubscription";
import { Plan } from "@/features/subscription/api/createCheckoutSession";
import { queryClient } from "@/lib/react-query";
import { useNotificationStore } from "@/stores/notifications";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateUserSubscription } from "../api/updateUserSubscription";

interface SubscriptionChangeDialogProps {
  isOpen: boolean;
  close: () => void;
  open: () => void;
  title: string;
  description: string | React.ReactNode;
  confirmButtonText: string;
  plan: Plan;
  hasAddOn?: boolean;
  pricingPeriod?: "monthly" | "yearly";
}

export const SubscriptionChangeDialog = ({
  isOpen,
  close,
  open,
  title,
  description,
  confirmButtonText,
  plan,
  hasAddOn = false,
  pricingPeriod,
}: SubscriptionChangeDialogProps) => {
  const updateSubscriptionMutation = useUpdateUserSubscription();
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState(false);

  const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      
      const plans = [{ plan: plan.stripeName }];
      if (hasAddOn) {
        plans.push({
          plan: pricingPeriod === "monthly" ? "addon" : "addon_yearly_357",
        });
      }

      // First attempt the subscription update
      const updateData = await updateSubscriptionMutation.mutateAsync({
        plans,
      });

      // Check if we need to handle payment authentication from the update response
      if (updateData.requires_payment) {
        if (updateData.client_secret) {
          const stripe = await stripePromise;
          if (!stripe) {
            throw new Error("Stripe failed to load");
          }

          const { error } = await stripe.confirmCardPayment(updateData.client_secret);
          if (error) {
            console.error("Payment authentication failed:", error);
            addNotification({
              type: "error",
              title: "Payment Failed",
              message: error.message || "Please update your payment information to continue with the subscription change.",
            });
            return;
          }
        } else {
          addNotification({
            type: "error",
            title: "Payment Required",
            message: "Your payment method was declined. Please update your payment information to continue with the subscription change.",
          });
          return;
        }
      }

      // Poll for subscription status to confirm the update
      const pollInterval = 1000; // 1 second
      const maxAttempts = 30; // 30 seconds total
      let attempts = 0;

      while (attempts < maxAttempts) {
        const subscriptionData = await getSubscription();

        // Handle pending authentication if needed
        if (subscriptionData.status === "pending_authentication" && subscriptionData.payment_intent_client_secret) {
          const stripe = await stripePromise;
          if (!stripe) {
            throw new Error("Stripe failed to load");
          }

          const { error } = await stripe.confirmCardPayment(subscriptionData.payment_intent_client_secret);
          if (error) {
            console.error("Payment authentication failed:", error);
            addNotification({
              type: "error",
              title: "Payment Failed",
              message: error.message || "Please update your payment information to continue with the subscription change.",
            });
            return;
          }
          // After handling payment authentication, continue polling
          continue;
        }

        // If plan is updated and not in a pending state, we're done
        if (
          subscriptionData.plan === plan.stripeName &&
          subscriptionData.status !== "past_due" &&
          subscriptionData.status !== "pending_authentication" &&
          subscriptionData.status !== "incomplete"
        ) {
          // Success case
          addNotification({
            type: "success",
            title: "Success",
            message: "Your subscription has been updated successfully.",
          });

          queryClient.invalidateQueries("subscription");
          close();
          setIsLoading(false);
          return;
        }

        // Wait before next poll
        await new Promise((resolve) => setTimeout(resolve, pollInterval));
        attempts++;
      }

      // If we reach here, polling timed out
      if (attempts >= maxAttempts) {
        addNotification({
          type: "error",
          title: "Change timeout",
          message: "The process took too long. Please refresh and try again.",
        });
        throw new Error("Process timed out");
      }
    } catch (error) {
      console.error("Subscription change failed:", error);
      setIsLoading(false);
      addNotification({
        type: "error",
        title: "Error",
        message: "Failed to update subscription. Please try again or contact support.",
      });
    }
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      close={() => {
        queryClient.invalidateQueries(["subscription"]);
        close();
      }}
      open={open}
      title={title}
      body={description}
      triggerButton={<></>}
      isLoading={isLoading}
      confirmButton={
        <Button
          onClick={handleConfirm}
          isLoading={isLoading}
          disabled={isLoading}
        >
          {confirmButtonText}
        </Button>
      }
    />
  );
};
